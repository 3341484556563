import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
})
export class LoadingComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService, private router: Router) {}

  ngOnInit() {
    // コンポーネントが表示されるときスピナー表示状態
    this.spinner.show();

    // Navigationでエラーになったら全部ルートに飛ばす
    this.router.errorHandler = (error: any) => {
      console.log(error);
      this.router.navigate(['/']);
    };

    // ナビゲーションの終了時、スピナーを非表示する
    // また、ページ最上部にスクロールする
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.spinner.hide();
      document.querySelector('.mat-sidenav-content').scrollTop = 0;
    });
  }
}
