import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

declare interface IDialogButtonEventHandler {
  label?: string;
  timing?: 'pressed' | 'closed';
  function?: (event?: UIEvent) => void;
}
export interface IMessageDialogData {
  title?: string;
  message?: string;
  messageParam?: any;
  cancel?: IDialogButtonEventHandler;
  callback?: IDialogButtonEventHandler;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message.dialog.html',
  styleUrls: ['./message.dialog.scss'],
})
export class MessageDialogComponent implements OnDestroy {
  private onDestroyExecuteFunction_: (event?: UIEvent) => void;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: IMessageDialogData,
    private diagref: MatDialogRef<MessageDialogComponent>,
    public translate: TranslateService
  ) {
    // タイトル
    if (!dialogData.title) {
      // メッセージIDに応じて適切なタイトルに置き換える
      if (dialogData.message && dialogData.message.match('MESSAGE.W-')) {
        dialogData.title = 'POPUP.TITLE.WARNING';
      } else if (dialogData.message && dialogData.message.match('MESSAGE.I-')) {
        dialogData.title = 'POPUP.TITLE.INFO';
      } else {
        dialogData.title = 'POPUP.TITLE.ERROR';
      }
    }

    // デフォルトメッセージ
    if (!dialogData.message) {
      dialogData.message = 'MESSAGE.DEFAULT_ERROR';
    }

    // Callbackが設定されているが、ラベルが設定されていない
    if (dialogData.callback && !dialogData.callback.label) {
      dialogData.callback.label = 'BUTTON.OK';
    }

    // Callbackが設定されているが、Cancel側が設定されていない
    if (dialogData.callback && !dialogData.cancel) {
      dialogData.cancel = {
        label: 'BUTTON.CANCEL',
        function: undefined,
        timing: 'closed',
      };
    }

    // Cancelが設定されているが、ラベルが設定されていない
    if (dialogData.cancel && !dialogData.cancel.label) {
      dialogData.cancel.label = 'BUTTON.CLOSE';
    }

    // Cancelが設定されていない
    if (!dialogData.cancel) {
      dialogData.cancel = {
        label: 'BUTTON.CLOSE',
        function: undefined,
        timing: 'closed',
      };
    }

    // ダイアログが破棄されるときに実行される処理のデフォルトはCancel側
    if (!dialogData.cancel.timing || dialogData.cancel.timing !== 'pressed') {
      this.onDestroyExecuteFunction_ = dialogData.cancel.function;
    }
  }

  handleClick(event: UIEvent, handler?: IDialogButtonEventHandler): void {
    // タイミングがボタン押下時に実行かどうか
    if (handler && handler.timing && handler.timing === 'pressed') {
      // 即時実行
      handler.function(event);
    } else if (handler) {
      // ウィンドウ破棄時に実行するように後回し
      this.onDestroyExecuteFunction_ = handler.function;
    }

    // callback側が設定されていて、それが選択されたときTRUEを返す
    this.diagref.close(handler && this.dialogData.callback && handler === this.dialogData.callback);
  }

  ngOnDestroy(): void {
    if (this.onDestroyExecuteFunction_) {
      this.onDestroyExecuteFunction_(null);
    }
  }
}
