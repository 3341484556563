import { Component, OnInit } from '@angular/core';
import { MetaService } from '@shared/services/meta/meta.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private metaService: MetaService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.setDefaultLang('ja');
    this.translate.use('ja');

    moment.locale('ja');

    this.metaService.injectMetaTag();
  }
}
