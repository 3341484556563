import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {
  constructor(private readonly translate: TranslateService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMS_PER_PAGE_LABEL');
    paginatorIntl.nextPageLabel = this.translate.instant('PAGINATOR.NEXT_PAGE_LABEL');
    paginatorIntl.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUS_PAGE_LABEL');
    paginatorIntl.firstPageLabel = this.translate.instant('PAGINATOR.FIRST_PAGE_LABEL');
    paginatorIntl.lastPageLabel = this.translate.instant('PAGINATOR.LAST_PAGE_LABEL');

    paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return this.translate.instant('PAGINATOR.RANGE_PAGE_LABEL_1', { length });
      }

      // ページ未満の場合も考慮して終了インデックスを算出
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return this.translate.instant('PAGINATOR.RANGE_PAGE_LABEL_2', {
        startIndex: startIndex + 1,
        endIndex,
        length,
      });
    };

    return paginatorIntl;
  }
}
