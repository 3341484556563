import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '@domains/auth/auth.facade';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnInit {
  constructor(private router: Router, private auth: AuthFacade) {}

  ngOnInit() {
    this.auth.handleAuthCallback().then((redirectTo) => this.router.navigateByUrl(redirectTo));
  }
}
