import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GetTokenSilentlyOptions, GetUserOptions } from '@auth0/auth0-spa-js';

import { State } from './store/auth.state';
import { AuthService } from './auth.service';
import * as AuthActions from './store/auth.action';
import * as AuthSelector from './store/auth.selector';
import { AuthStoreModule } from './store/auth-store.module';
import { Auth0UserProfile } from '@interface/auth0.interface';

@Injectable({
  providedIn: AuthStoreModule,
})
export class AuthFacade {
  readonly loggedIn$ = this.store.pipe(select(AuthSelector.getLoggedIn));
  readonly userProfile$ = this.store.pipe(select(AuthSelector.getUserProfile));

  readonly login = (redirectPath?: string) => this.service.login(redirectPath);
  readonly logout = () => this.service.logout();
  readonly getUser$ = (options?: GetUserOptions) => this.service.getUser$(options);
  readonly getTokenSilently$ = (options?: GetTokenSilentlyOptions) =>
    this.service.getTokenSilently$(options);

  constructor(private store: Store<State>, private service: AuthService) {}

  public get accessToken(): string {
    return sessionStorage.getItem('accessToken');
  }

  public set accessToken(value: string) {
    sessionStorage.setItem('accessToken', value);
  }

  clear() {
    sessionStorage.clear();
  }

  setAuth(userProfile: Auth0UserProfile) {
    this.store.dispatch(AuthActions.setAuth({ loggedIn: true, userProfile }));
  }

  async handleAuthCallback() {
    const result = await this.service.handleAuthCallback();
    this.accessToken = await this.getTokenSilently$().toPromise();
    return result;
  }
}
