import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { MetaPageHead } from './meta.interface';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  injectMetaTag() {
    this.injectNoindxIfDevelopment();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe((event: MetaPageHead) => {
        this.title.setTitle(event.title);
        this.meta.updateTag(event.description);
      });
  }

  private injectNoindxIfDevelopment(): void {
    if (!environment.production) {
      this.meta.addTag({
        name: 'robots',
        content: 'noindex,nofollow'
      });
    }
  }
}
