import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { MessageDialogComponent, IMessageDialogData } from '@shared/dialog/message.dialog';

@Injectable()
export class AggregationErrorHandler implements ErrorHandler {
  private isPopupedDialog = false;

  constructor(private router: Router, private injector: Injector, private ngzone: NgZone) {}

  /**
   * エラーオブジェクトからエラーメッセージ用ダイアログデータを作成する
   *
   * @param {any} error
   * @returns {IMessageDialogData}
   * @memberof AggregationErrorHandler
   */
  createErroMessageDialogDataFromError(error: any): IMessageDialogData {
    const output: IMessageDialogData = {
      message: 'MESSAGE.DEFAULT_ERROR',
    };

    // エラーオブジェクトからエラーメッセージ部分だけを抽出して設定
    const targetException = error.rejection || error;
    if (targetException.message) {
      output.message = targetException.message;
    }

    // ForceReloadExceptionされているか または
    // エラーメッセージが'MESSAGE.TOKEN_IS_EXPIRED'だったら強制ログアウト
    if (
      (targetException.name && targetException.name === 'ForceReloadException') ||
      output.message === 'MESSAGE.TOKEN_IS_EXPIRED'
    ) {
      output.cancel = { function: () => this.reload() };
    }

    return output;
  }

  reload() {
    this.router.navigate(['/auth/logout']);
  }

  handleError(error) {
    console.log('AggregationErrorHandler - handleError', error);

    // 型エラーの場合は無視する
    if (error instanceof TypeError) {
      return;
    }

    if (!this.isPopupedDialog) {
      this.isPopupedDialog = true;

      this.ngzone.run(() => {
        const dialog: MatDialog = this.injector.get(MatDialog);
        dialog
          .open(MessageDialogComponent, { data: this.createErroMessageDialogDataFromError(error) })
          .afterClosed()
          .subscribe(() => {
            this.isPopupedDialog = false;
          });
      });
    }
  }
}
