import { Injectable } from '@angular/core';
import { from, Observable, throwError, combineLatest } from 'rxjs';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { catchError, concatMap, shareReplay, map, first } from 'rxjs/operators';
import createAuth0Client, { GetTokenSilentlyOptions, GetUserOptions } from '@auth0/auth0-spa-js';

import { AuthApiService } from './auth-api.service';
import { Auth0Config } from '@interface/auth0.interface';
import { AuthStoreModule } from './store/auth-store.module';

@Injectable({
  providedIn: AuthStoreModule,
})
export class AuthService {
  private config$: Observable<Auth0Config>;
  private auth0Client$: Observable<Auth0Client>;

  get config() {
    if (!this.config$) {
      this.config$ = this.authApi.getConfig().pipe(
        map((res) => JSON.parse(res)),
        shareReplay(1), // Every subscription receives the same shared value
        catchError((err) => throwError(err))
      );
    }
    return this.config$;
  }

  get auth0Client() {
    if (!this.auth0Client$) {
      this.auth0Client$ = this.config.pipe(
        concatMap((config: Auth0Config) =>
          from(
            createAuth0Client({
              domain: config.domain,
              client_id: config.clientId,
              redirect_uri: `${window.location.origin}/auth/callback`,
              audience: config.audience,
            })
          )
        ),
        shareReplay(1), // Every subscription receives the same shared value
        catchError((err) => throwError(err))
      );
    }
    return this.auth0Client$;
  }

  private handleRedirectCallback$ = this.auth0Client.pipe(
    concatMap((client) => from(client.handleRedirectCallback()))
  );

  constructor(private authApi: AuthApiService) {}

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?: GetUserOptions): Observable<any> {
    return this.auth0Client.pipe(concatMap((client) => from(client.getUser(options))));
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently
  getTokenSilently$(options?: GetTokenSilentlyOptions): Observable<string> {
    return this.auth0Client.pipe(concatMap((client) => from(client.getTokenSilently(options))));
  }

  login(redirectPath: string = '/'): void {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/auth/callback`,
        appState: { target: redirectPath },
      });
    });
  }

  handleAuthCallback(): Promise<string> {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        map((cbRes) => {
          const target: string =
            cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
          return target.replace(/\?.*$/, ''); // NOTE: URLにパラメータが含まれていた場合はそのパラメータを除外
        })
      );
      return authComplete$.toPromise();
    }
  }

  logout(): void {
    // Ensure Auth0 client instance exists
    combineLatest([this.config, this.auth0Client])
      .pipe(
        first(),
        map(([config, client]: [Auth0Config, Auth0Client]) => {
          client.logout({
            client_id: config.clientId,
            returnTo: `${window.location.origin}/auth/logout`,
          });
        })
      )
      .toPromise();
  }
}
