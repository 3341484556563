import { Auth0UserProfile } from '@interface/auth0.interface';

export const featureName = 'auth';
export interface State {
  loggedIn: boolean;
  userProfile: Auth0UserProfile;
}
export const initialState: State = {
  loggedIn: false,
  userProfile: null,
};
