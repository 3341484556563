import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthFacade } from '@domains/auth/auth.facade';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
  constructor(private router: Router, private auth: AuthFacade) {}

  ngOnInit() {
    console.log('[LogoutComponent] OnInit');

    // セッション変数他ログイン認証情報をクリアする
    this.auth.clear();

    // ルートにリダイレクトする
    this.auth.login('/');
  }
}
