import { Routes } from '@angular/router';
import { rootRoutes } from './routes';

export const environment = {
  production: false,
  mock: false,
  noticesUrl: `https://fs2.jgrants-portal.go.jp/${window.location.hostname}.json`,
};

const debugRoutes: Routes = [
  // 必要があればここにデバッグ用のRouteを追加する
];

export const routes: Routes = [...rootRoutes, ...debugRoutes];
