import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MockInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const mockRequest = this.makeMockRequest(req.method, req.url);
    const newReq = req.clone({ ...mockRequest });
    return next.handle(newReq);
  }

  /**
   * make mock method and url.
   * @param method http method
   * @param url request url
   */
  private makeMockRequest(method: string, url: string): { method: string; url: string } {
    const urlSplitArr = url.split('/');
    // if url is contains 'api' change to mock url
    if (urlSplitArr.length > 1 && urlSplitArr[1] === 'api') {
      // if the last word of url is '/' return default **/get.json
      if (url.substr(url.length - 1) === '/') {
        url = url + 'get';
      }
      // method
      const mockMethod = 'GET';
      // change url
      const mockUrl = `mock/${method}${url}.json`;
      return { method: mockMethod, url: mockUrl };
    }
    return { method, url };
  }
}
