import { Observable } from 'rxjs';
import { AuthFacade } from '@domains/auth/auth.facade';
import { Inject, Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

@Injectable()
export class TokenAppendAuth0InterceptorService implements HttpInterceptor {
  private auth: AuthFacade;

  constructor(
    private injector: Injector,
    @Inject('API_URL') private apiUrl: string
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // apiでないものは無視する
    if (!req.url.startsWith(this.apiUrl)) {
      return next.handle(req);
    }

    // get it here within intercept
    this.auth = this.injector.get(AuthFacade);

    const token = this.auth.accessToken;
    // アクセストークンがなければ無視する
    if (!token) {
      return next.handle(req);
    }

    const tokenReq = req.clone({
      url: req.url,
      setHeaders: { Authorization: `Bearer ${token}` },
      responseType: req.responseType || 'json',
    });

    return next.handle(tokenReq);
  }
}
