import { Routes } from '@angular/router';
import { LogoutComponent } from '@pages/logout/logout.component';
import { CallbackComponent } from '@pages/callback/callback.component';

export const rootRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@pages/navigation/navigation.module').then((m) => m.NavigationModule),
  },
  // 通知文書プレビュー
  {
    path: 'notification/:notificationDocumentSfid',
    loadChildren: () =>
      import('@pages/notification-preview/notification-preview.module').then(
        (m) => m.NotificationPreviewModule
      ),
  },
  // Callback設定(開発用)
  {
    path: 'callback',
    redirectTo: 'auth/callback',
    pathMatch: 'full',
  },
  {
    path: 'auth/callback',
    component: CallbackComponent,
  },
  // auth0 logout
  {
    path: 'auth/logout',
    component: LogoutComponent,
  },
];
