import { ErrorHandler, NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MessageDialogModule } from '@shared/dialog/message.dialog.module';
import { AggregationErrorHandler } from './aggregation.error-handler';

@NgModule({
  imports: [MatDialogModule, MessageDialogModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AggregationErrorHandler,
    },
  ],
})
export class ErrorHandlerModule {}
